<template>
  <base-layout>
  <div class="novedad">  
  <register-novedad-form></register-novedad-form>
  </div>
  </base-layout>
</template>

<script>
import RegisterNovedadForm from '../components/employe/RegisterNovedadForm.vue';
export default {
  components: {RegisterNovedadForm},
    name: 'novedad-page'

}
</script>

<style>
.novedad{
  padding: auto;
  align-items: center;
  margin-top: 10%;
}
</style>