<template>
  <form class="ion-padding" @submit.prevent="submitForm">    
    <ion-list>
    <ion-item>
        <ion-label position="stacked">Tipo Novedad</ion-label>
        <ion-select placeholder="Seleccionar tipo novedad" v-model="dataNovedad.tipo" ok-text="Ok" cancel-text="Cancelar">
          <ion-select-option value="incapacidad"
            >Incapacidad EPS</ion-select-option
          >
          <ion-select-option value="accidente"
            >Accidente Laboral</ion-select-option
          >
          <ion-select-option value="cita medica"
            >Cita Médica</ion-select-option
          >
          <ion-select-option value="otro">Otra Novedad</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label v-if="dataNovedad.tipo === 'incapacidad'" position="stacked">Fecha Inicio</ion-label>
        <ion-label v-if="dataNovedad.tipo === 'accidente' && dataNovedad.tipo !== 'incapacidad'" position="stacked">Fecha Incidente</ion-label>
        <ion-label v-if="dataNovedad.tipo === 'otro' || dataNovedad.tipo === 'cita medica' &&(dataNovedad.tipo !== 'incapacidad' || dataNovedad.tipo !== 'accidente')" position="stacked">Fecha</ion-label>
        <ion-datetime
          v-model="fechaInicio"
          min="2020"
          max="2025"
          display-format="DDDD DD/MM/YYYY"
          :day-names="dayNames"
          cancel-text="Cancelar" done-text="Hecho" 
          placeholder="Seleccionar fecha"
        ></ion-datetime>
      </ion-item>
      <ion-item v-if="dataNovedad.tipo == 'incapacidad'">
        <ion-label position="stacked">Dias</ion-label>
        <ion-input type="number" min="0" v-model="dataNovedad.dias"></ion-input>
      </ion-item>
      <ion-item>
        <ion-button
          v-if="!file"
          class="ionic-padding"
          type="button"
          expand="clear"
          @click="$refs.f.click()"
        >
          <ion-icon lazy="true" slot="start" :icon="attachOutline"></ion-icon>
          <ion-label>Agregar Soporte</ion-label>
        </ion-button>
        <ion-label>{{ archivo }}</ion-label>
        <ion-button
          v-if="file"
          type="button"
          fill="clear"
          size="large"
          fab
          @click="$refs.f.click()"
        >
          <ion-icon slot="end" :icon="swapHorizontalOutline"></ion-icon>
        </ion-button>
        <input
          class="ion-hide"
          ref="f"
          type="file"
          @change="selectedFile"
          accept="file/pdf"
        />
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Observaciones</ion-label>
        <ion-textarea rows="3" v-model="dataNovedad.obs"></ion-textarea>
      </ion-item>
    </ion-list>
    <ion-button type="submit" expand="block" fill="outline">Enviar</ion-button>
  </form>
</template>

<script>
import {
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonInput,
  IonTextarea,
  IonButton,
  IonIcon,
  toastController,
  IonSelect,
  IonSelectOption,
  loadingController,
} from "@ionic/vue";
import { swapHorizontalOutline, attachOutline } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "register-novedad-form",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonDatetime,
    IonInput,
    IonTextarea,
    IonButton,
    IonIcon,
    IonSelect,
    IonSelectOption
  },
  setup() {
    const dayNames = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];

    return {
      dayNames,
    };
  },
  data() {
    return {
      dataNovedad: {
        userId: "",
        fecha: null,
        dias: 0,
        fechaInicio: null,
        tipo:'Incapacidad',        
        obs: null,
      },
      fechaInicio: null,
      file: null,
      imageUrl: "assets/icon/favicon.png",
      archivo: "",
      swapHorizontalOutline,
      attachOutline,
      timeout:1000,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions("entrada", ["registerNovedad"]),
    async submitForm() {
      this.timeout = 100000;
      const loading = await loadingController.create({
          message: "Enviando información...",
          duration: this.timeout,
        });
        await loading.present();
      this.dataNovedad.fechaInicio = await this.timeConverter(this.fechaInicio);
      this.dataNovedad.fecha = await this.timeConverter(new Date());
      const fD = new FormData();
      fD.append('user_id', this.dataNovedad.userId);
      fD.append('fecha_inicio', this.dataNovedad.fechaInicio);
      fD.append('fecha', this.dataNovedad.fecha);
      fD.append('tipo', this.dataNovedad.tipo);
      fD.append('observaciones', this.dataNovedad.obs);
      fD.append('soporte',this.file); 
      const result = await this.registerNovedad(fD);
      if(result.status === 201){
          this.$router.replace({ name: "home" });
          this.toatsColor = "light";
          this.toatsMessage = "Registro exitoso";
          this.openToast();
        } else if (result.status === 500) {
          this.toatsColor = "danger";
          this.toatsMessage = `¡Error interno del servidor!.<br>Contacte al administrador`;
          this.openToast();
        } else if (result.status === 422) {
          let errorMessate = "";
          const errors = await JSON.parse(JSON.stringify(result.data.errors));
          for (const i in errors) {            
            errorMessate = await errors[i];
          }
          this.toatsColor = "danger";
          this.toatsMessage = `No se ha enviado toda la información<br>¡Erores!<br>${errorMessate}`;
          await this.openToast();
        } else {
          this.toatsColor = "danger";
          this.toatsMessage = "Error. No se ha realizado el registro";
          this.openToast();
        }
        loading.dismiss();
    },
    selectedFile(event) {
      let file = event.target.files[0];
      const ext = file.name.split('.').pop();
      if(ext === 'pdf' || ext === 'jpg' || ext === 'jpeg' || ext === 'PDF' || ext === 'JPG' || ext === 'JPEG'){
        this.file = file;
        this.archivo = this.file.name;
      }else{
          this.toatsColor = "warning";
          this.toatsMessage = "Tipo de archivo no admitido -- solo PDF o JPG";
          this.openToast();
      }
      
    },
    loadData() {
      this.dataNovedad.userId = this.user.id;
    },
    async openToast() {
      const toast = await toastController.create({
        message: this.toatsMessage,
        position: "middle",
        color: this.toatsColor,
        duration: 2000,
      });
      return toast.present();
    },
    async timeConverter(timestamp) {
      const a = new Date(timestamp);
      const year = a.getFullYear();
      const month = a.getMonth() + 1;
      const date = a.getDate();
      const hour = a.getHours();
      const min = a.getMinutes();
      const sec = a.getSeconds();
      const time = `${year}-${month}-${date} ${hour}:${min}:${sec}`;
      return time;
    },
  },
};
</script>

<style>
</style>